// Plan Tree
export const planTreeInfo = 'plan-tree-info';
export const planTreeModal = 'plan-tree-modal';

// Plan NetService
export const planNetServiceInfoIncluded = 'plan-netservice-info-included';
export const planNetServiceInfoNotIncluded =
  'plan-netservice-info-not-included';
export const planNetServiceModal = 'plan-netservice-modal';

export const iadBasicRecommendationPlan = 'basic_recommendation_plan';
export const iadPremiumRecommendationPlan = 'premium_recommendation_plan';

// expansion check
export const eCheckCableAvailable = 'expansion_CABLE_AVAILABLE';
export const eCheckCablePresaleAvailable = 'expansion_CABLE_PRESALE_AVAILABLE';
export const eCheckCablePresale = 'expansion_CABLE_PRESALE';
export const eCheckNotPlanned = 'expansion_NOT_PLANNED';
export const eCheckHomesPassed = 'expansion_HOMES_PASSED';
export const eCheckHomesPassedAvailable = 'expansion_HOMES_PASSED_AVAILABLE';
export const eCheckFTTP = 'expansion_FTTB';
export const eCheckAreaPlanned = 'expansion_AREA_PLANNED';
export const eCheckAreaPlannedDate = 'expansion_AREA_PLANNED_DATE';
export const eCheckHomesConnected = 'expansion_HOMES_CONNECTED';
export const eCheckHomesPrepared = 'expansion_HOMES_PREPARED';
export const eCheckHomesPreparedDate = 'expansion_HOMES_PREPARED_DATE';
export const eCheckOutOfArea = 'expansion_OUT_OF_AREA';
export const eCheckPreMarketing = 'expansion_PRE_MARKETING';
export const eCheckPreMarketingDate = 'expansion_PRE_MARKETING_DATE';
export const eCheckPresale = 'expansion_PRESALE';
export const eCheckPresaleAvailable = 'expansion_PRESALE_AVAILABLE';
export const eCheckUnderConstruction = 'expansion_UNDER_CONSTRUCTION';
export const eCheckUnderConstructionDate = 'expansion_UNDER_CONSTRUCTION_DATE';

export const maintenanceInfoBar = 'maintenance-info-bar';

// tv
export const tvModalCable = 'nettv-modal-NET_TV_CABLE';
export const tvModalInternet = [
  'nettv-modal-internet-card',
  'nettv-modal-internet-text',
  'nettv-modal-internet-box',
];
