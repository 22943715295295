import React from 'react';
import {
  Expandable,
  IconChevron,
  RichText,
  Spacer,
  Text,
} from 'dss-ui-library';
import planStyles from '../PlanElements.module.scss';
import { ProductInfoDetails, useProductContext } from '../../ProductContext';
import cx from 'classnames';
import { contentApiURL } from '../../../utils';
import useSWR from 'swr';
import { TextLink } from '@ncs-frontend-monorepo/content-library';
import { useModalContext } from '../../ModalContext';
import { ModuleText } from '../../../interfaces/content';
import { fetcher } from '@ncs-frontend-monorepo/utils';

interface InfoDetailProps {
  disabled?: boolean;
  type: ProductInfoDetails;
  contentKey: string;
  e2e: string;
  icon: React.ReactNode;
  modalContent: React.ReactNode;
}

export const InfoDetail: React.FC<InfoDetailProps> = ({
  type,
  contentKey,
  e2e,
  icon,
  modalContent,
}) => {
  const { toggledDetail, toggleInfo } = useProductContext();
  const { handleModal } = useModalContext();
  const open = toggledDetail.includes(type);
  const contactSuccess = `${contentApiURL()}${contentKey}`;
  const { data } = useSWR<ModuleText>(contactSuccess, fetcher);

  const handleClick = () => {
    handleModal({
      content: modalContent,
      e2e: `plan-${e2e}-info-modal`,
    });
  };

  return (
    <>
      <div className={planStyles.description} onClick={() => toggleInfo(type)}>
        <div className={planStyles.descriptionHeadline}>
          <Spacer r={1} block>
            {icon}
          </Spacer>
          <Text appearance="t5_2">{data?.content.props.headline}</Text>
        </div>
        <IconChevron
          small
          color="blue"
          width={24}
          height={24}
          className={cx(planStyles.toggleIcon, open && planStyles.open)}
        />
      </div>
      <Expandable e2e={`plan-${e2e}-info-description`} expanded={open}>
        {data && (
          <Spacer t={1} l={3} block>
            <RichText text={data?.content.props.text} appearance="t5" />
            <Spacer t={1} block />
            <TextLink
              url={null}
              onClick={handleClick}
              e2e={`plan-${e2e}-more-info`}
            >
              <Text appearance="t5">Mehr Informationen</Text>
            </TextLink>
          </Spacer>
        )}
      </Expandable>
    </>
  );
};
