import React from 'react';
import cx from 'classnames';
import styles from './SlideIndicator.module.scss';

interface SlideIndicatorProps {
  slides: boolean[];
  onItemClick?: (index: number) => void;
}

const SlideIndicator: React.FC<SlideIndicatorProps> = ({
  slides,
  onItemClick,
}) => {
  const firstActiveIndex = slides.findIndex((visible) => visible);
  const activeItemsLength = slides.filter((visible) => visible).length;
  return (
    <div className={styles.slideIndicator}>
      {slides.map((visible, ix) => (
        <div
          key={ix}
          onClick={() => onItemClick?.(ix)}
          className={cx(styles.slideIndicatorDot, {
            [styles.active]: visible,
          })}
        />
      ))}
      <div
        className={styles.activeSlideIndicatorDot}
        style={{
          transform: `translateX(${firstActiveIndex * 20}px)`,
          width: `${8 * activeItemsLength + (activeItemsLength - 1) * 12}px`,
        }}
      />
    </div>
  );
};

export default SlideIndicator;
