import cx from 'classnames';
import dynamic from 'next/dynamic';
import planStyles from '../PlanElements.module.scss';
import { IconCheck, IconClose, LoadingSpinner } from 'dss-ui-library';
import {
  planNetServiceInfoIncluded,
  planNetServiceInfoNotIncluded,
} from '../../../constants/contentBlocks';
import { ProductInfoDetails } from '../../ProductContext';
import { InfoDetail } from '../InfoDetail';

interface NetServiceInfoProps {
  disabled?: boolean;
  isIncluded: boolean;
}

const NetServiceInfoModal = dynamic(
  () => import('../../Modals/NetServiceInfoModal').then(({ Index }) => Index),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

export const NetServiceInfo: React.FC<NetServiceInfoProps> = ({
  disabled = false,
  isIncluded,
}) => {
  return (
    <InfoDetail
      disabled={disabled}
      contentKey={
        isIncluded ? planNetServiceInfoIncluded : planNetServiceInfoNotIncluded
      }
      e2e="netservice"
      type={ProductInfoDetails.netServiceInfo}
      icon={
        isIncluded ? (
          <IconCheck
            className={cx({
              [planStyles.isDisabled]: disabled,
            })}
            scale="small"
            color="blue"
          />
        ) : (
          <IconClose
            className={cx({
              [planStyles.isDisabled]: disabled,
            })}
            scale="small"
            color="blue"
          />
        )
      }
      modalContent={<NetServiceInfoModal />}
    />
  );
};
