import React from 'react';
import { IconTree, LoadingSpinner } from 'dss-ui-library';
import styles from './TreeInfo.module.scss';
import planStyles from '../PlanElements.module.scss';
import { ProductInfoDetails } from '../../ProductContext';
import cx from 'classnames';
import { planTreeInfo } from '../../../constants/contentBlocks';
import dynamic from 'next/dynamic';
import { InfoDetail } from '../InfoDetail';

interface TreeInfoProps {
  disabled?: boolean;
}

export const TreeInfo: React.FC<TreeInfoProps> = ({ disabled = false }) => {
  const TreeInfoModal = dynamic(
    () => import('../../Modals/TreeInfoModal').then(({ Index }) => Index),
    {
      ssr: false,
      loading: () => <LoadingSpinner theme="blue" />,
    },
  );

  return (
    <InfoDetail
      contentKey={planTreeInfo}
      e2e="tree"
      type={ProductInfoDetails.treeInfo}
      modalContent={<TreeInfoModal />}
      icon={
        <IconTree
          className={cx(styles.treeIcon, {
            [planStyles.isDisabled]: disabled,
          })}
          scale="small"
          color="green"
        />
      }
    />
  );
};
