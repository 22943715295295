import { Flag, Footnote, Spacer, Text } from 'dss-ui-library';
import { TextLink } from '@ncs-frontend-monorepo/content-library';
import NextImage from 'next/image';
import React from 'react';
import { ProductInfo } from '../../../interfaces';
import { useModalContext } from '../../ModalContext';
import { ProductModal } from '../../Modals/ProductModal';
import styles from './IadDevice.module.scss';
import { getImageProps } from '@ncs-frontend-monorepo/utils';

export const IadDevice: React.FC<ProductInfo> = ({
  name,
  image,
  icon,
  flag,
  description,
  category,
  type,
  disabled,
  shortDesc,
  footnoteFlag,
}) => {
  const { handleModal } = useModalContext();

  const handleClick = () => {
    handleModal({
      content: (
        <ProductModal
          image={image}
          description={description}
          flag={flag}
          name={name}
          icon={icon}
          shortDescription={
            type === 'device' &&
            `${category === 'premium' ? 'Premium' : 'Basic'} WLAN-Router`
          }
        />
      ),
      e2e: `product-modal`,
    });
  };
  return (
    <div
      className={disabled ? styles.isDisabled : undefined}
      data-e2e={'device-recommendation'}
    >
      <Spacer b={1} block>
        <Text appearance="t5">{shortDesc}</Text>
      </Spacer>
      <Spacer b={1} block>
        {flag ? (
          <Flag
            className={styles.iaDeviceFlagText}
            color="red"
            footnote={
              footnoteFlag && (
                <Footnote number={footnoteFlag.number} color="white" />
              )
            }
          >
            {flag}
          </Flag>
        ) : (
          <div className={styles.iaDeviceFlagPlaceholder} />
        )}
      </Spacer>
      <div className={styles.iaDeviceInfo}>
        <Text appearance="t5_2">{name}</Text>
        <div className={styles.iaDevice}>
          <NextImage
            {...getImageProps(image)}
            alt={name}
            className={styles.iaDevice}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
      </div>
      <TextLink url={null} onClick={handleClick} e2e="iad-info">
        <Text appearance="t5">Mehr Informationen</Text>
      </TextLink>
    </div>
  );
};
