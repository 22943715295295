import React, { useEffect, useRef } from 'react';
import styles from './Tabs.module.scss';
import { PriceText, Text } from 'dss-ui-library';
import cx from 'classnames';
import { OFFSET_LEFT_SCROLL } from '../../hooks/useTabNavigation';
import { BESTSELLER_TEXT, TOPPRICETEXT } from '../../constants';

export interface Item {
  headline: string;
  price: number;
  isBestseller: boolean;
  isTopprice: boolean;
  presaleDate?: string;
}

export interface TabsProps {
  items: Item[];
  onSelected?(index: number): void;
  selected?: number;
}

export const Tabs: React.FC<TabsProps> = ({
  items,
  onSelected,
  selected = -1,
}) => {
  const tabsRef = useRef(null);
  useEffect(() => {
    if (tabsRef.current && selected > -1) {
      const tabWidth =
        (tabsRef.current?.firstChild?.firstChild as HTMLElement)?.clientWidth ||
        0;
      const left = (tabWidth + OFFSET_LEFT_SCROLL) * selected;

      tabsRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
        left,
      });
    }
  }, [selected]);

  return (
    <div className={styles.tabs} data-e2e="tabs-navigation" ref={tabsRef}>
      {items.map((item: Item, itemIdx: number) => {
        const active = selected === itemIdx;
        const activeColor = active ? 'white' : 'blue';
        const highlightFlags = [
          item.presaleDate,
          item.isBestseller && BESTSELLER_TEXT,
          item.isTopprice && TOPPRICETEXT,
        ].filter(Boolean);

        return (
          <div
            className={styles.tab}
            key={`tab-${itemIdx}-${item.headline}`}
            data-e2e={`tab-${itemIdx}`}
            onClick={() => onSelected(itemIdx)}
          >
            {highlightFlags.length > 0 && (
              <div className={styles.bestseller} data-e2e="bestseller">
                <Text appearance="t7_2">{highlightFlags[0]}</Text>
              </div>
            )}
            <div
              className={cx(styles.content, {
                [styles.active]: active,
              })}
            >
              <div className={styles.upper}>
                <div className={styles.tshirtSize}>
                  <Text appearance="t5_2" color={activeColor}>
                    {item.headline}
                  </Text>
                </div>
              </div>
              <PriceText
                price={item.price}
                info="mtl."
                color={activeColor}
                infoColor={active ? 'white' : 'black'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
