import { Flag, Grid, Spacer, Text, RichText, ListIcon } from 'dss-ui-library';
import { getImageProps } from '@ncs-frontend-monorepo/utils';
import NextImage from 'next/image';
import { FC } from 'react';
import { ProductInfoWithPrice } from '../../../interfaces';
import modalStyles from '../Modal.module.scss';

export interface ProductModalProps
  extends Pick<
    ProductInfoWithPrice,
    'description' | 'image' | 'flag' | 'name' | 'icon'
  > {
  shortDescription?: string;
}

const ProductModal: FC<ProductModalProps> = ({
  image,
  flag,
  description,
  name,
  icon,
  shortDescription,
}) => {
  return (
    <>
      <Grid className={modalStyles.infoBox} fullWidth>
        <Grid.Row>
          <Grid.Column s={6} m={7}>
            {flag && (
              <Spacer b={1} block>
                <Flag color="red">{flag}</Flag>
              </Spacer>
            )}
            {shortDescription && (
              <Text appearance="t2" color="blue" block>
                {shortDescription}
              </Text>
            )}
            <Text appearance="t2" color="blue" block>
              {name}
            </Text>
            <Spacer b={2} block />
          </Grid.Column>
          {image && (
            <Grid.Column s={6} m={5}>
              <NextImage
                {...getImageProps(image)}
                width={700}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Grid.Column>
          )}
          {icon && (
            <Grid.Column s={6} m={5} hEnd>
              <ListIcon size={80} iconType={icon} iconColor="blue" />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <Spacer t={4} />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <RichText text={description} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export { ProductModal };
