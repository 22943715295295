import {
  useEffect,
  useState,
  MutableRefObject,
  useCallback,
  useRef,
} from 'react';

import { getObserver } from '@ncs-frontend-monorepo/utils';
import { OFFSET_LEFT_SCROLL } from './useTabNavigation';

interface SelectedSlides {
  visibleSlidesIndices: number[];
  invisibleSlidesIndices: number[];
  singleVisibleSlideIndex: number;
  slideVisibilityMatrix: boolean[];
}

const initialValues = {
  invisibleSlidesIndices: [],
  visibleSlidesIndices: [],
  singleVisibleSlideIndex: -1,
  slideVisibilityMatrix: [],
};

export const useScrollSlider = (
  wrapperRef: MutableRefObject<HTMLDivElement>,
  initialSlide = 0,
  synchronizableDiv?: MutableRefObject<HTMLDivElement>,
): {
  liveValues: SelectedSlides;
  afterScrollValues: SelectedSlides;
  scrollToSlide: (index: number, withAnimation?: boolean) => void;
  manualScroll: (direction: 'prev' | 'next') => void;
} => {
  const [liveValues, setLiveSlideVisibility] =
    useState<SelectedSlides>(initialValues);
  const [afterScrollValues, setAfterScrollSlideVisibility] =
    useState<SelectedSlides>(initialValues);
  const [isReady, setIsReady] = useState(false);
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const slideRefs = useRef<HTMLElement[]>([]);
  const observer = useRef<IntersectionObserver>(null);
  const visibleSlidesIndices = useRef<number[]>([]);
  const intersectionThreshold = 0.6;

  const addNode = useCallback((node: HTMLElement, index: number) => {
    slideRefs.current[index] = node;
  }, []);

  const getSlideWidth = useCallback(
    () =>
      (wrapperRef.current?.firstChild?.firstChild as HTMLElement)
        ?.clientWidth || 0,
    [],
  );

  const getSlideVisibilities = () => {
    let singleVisibleSlideIndex = -1;
    if (visibleSlidesIndices.current.length) {
      if (visibleSlidesIndices.current.includes(0)) {
        singleVisibleSlideIndex = 0;
      } else if (visibleSlidesIndices.current.length > 0) {
        singleVisibleSlideIndex =
          visibleSlidesIndices.current[
            Math.floor(visibleSlidesIndices.current.length / 2)
          ];
      } else {
        singleVisibleSlideIndex = visibleSlidesIndices.current[0];
      }
    }
    return {
      invisibleSlidesIndices: slideRefs.current
        .map((_, i) => i)
        .filter((_, index) => !visibleSlidesIndices.current.includes(index)),
      visibleSlidesIndices: visibleSlidesIndices.current,
      singleVisibleSlideIndex,
      slideVisibilityMatrix: slideRefs.current.map((_, i) =>
        visibleSlidesIndices.current.includes(i),
      ),
    };
  };

  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        const index = slideRefs.current.findIndex(
          (ref) => ref === entry.target,
        );

        if (entry.intersectionRatio >= intersectionThreshold) {
          // Add new item and remove duplicates
          visibleSlidesIndices.current = [
            ...visibleSlidesIndices.current,
            index,
          ].reduce(
            (unique, item) =>
              unique.includes(item) ? unique : [...unique, item],
            [],
          );
          visibleSlidesIndices.current.sort();
        } else {
          visibleSlidesIndices.current = visibleSlidesIndices.current.filter(
            (item) => item !== index,
          );
        }
        setLiveSlideVisibility(getSlideVisibilities);
      });
    },
    [],
  );

  const scrollToSlide = useCallback((index: number, withAnimation = true) => {
    if (!wrapperRef.current) return;
    const slideWidth = getSlideWidth();
    const slideLeft =
      index === 0
        ? -100 // fix for FF
        : (slideWidth + OFFSET_LEFT_SCROLL * 2) * index -
          wrapperRef.current.clientWidth * 0.5 +
          slideWidth * 0.5;
    const left = slideLeft;
    if (withAnimation) {
      wrapperRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
        left,
      });
    } else {
      wrapperRef.current.scrollLeft = left;
    }
  }, []);

  const manualScroll = (direction: 'prev' | 'next') => {
    if (wrapperRef.current) {
      const dir = direction === 'prev' ? -1 : 1;
      const slideWidth = getSlideWidth();
      wrapperRef.current.scrollBy({
        top: 0,
        behavior: 'smooth',
        left: (slideWidth + OFFSET_LEFT_SCROLL) * dir,
      });
    }
  };

  const handleScroll = () => {
    scrollTimeout.current && clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      scrollTimeout.current = null;
      setAfterScrollSlideVisibility(getSlideVisibilities);
    }, 250);

    if (synchronizableDiv?.current) {
      synchronizableDiv.current.scrollLeft = wrapperRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (isReady) {
      if (observer.current) observer.current.disconnect();
      const newObserver = getObserver(
        wrapperRef.current,
        observer,
        intersectionCallback,
        intersectionThreshold,
      );

      if (wrapperRef?.current?.children?.length) {
        Array.from(wrapperRef?.current?.children).forEach((node, index) => {
          if (node) {
            addNode(node as HTMLElement, index);
            newObserver.observe(node);
          }
        });

        if (!visibleSlidesIndices.current.includes(initialSlide)) {
          setTimeout(() => {
            if (initialSlide > 0) {
              scrollToSlide(initialSlide, false);
            }
          }, 250);
        }

        setAfterScrollSlideVisibility(liveValues);
      }
      return () => newObserver.disconnect();
    }
  }, [wrapperRef?.current?.children?.length, isReady]);

  useEffect(() => {
    async function checkPolyFills() {
      if (!('scrollBehavior' in document.documentElement.style)) {
        import('smoothscroll-polyfill').then((smoothscroll) =>
          smoothscroll.polyfill(),
        );
      }
      if (typeof window.IntersectionObserver === 'undefined') {
        await import('intersection-observer');
      }
      setIsReady(true);
    }
    checkPolyFills();

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll, {
        passive: true,
      });
    }

    return () => {
      wrapperRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    liveValues,
    afterScrollValues,
    scrollToSlide,
    manualScroll,
  };
};
